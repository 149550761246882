* {
    margin: 0;
    padding: 0;
    font-family: Clear Sans Bold;
}
.reactPlayer{
    margin-left: 25%;
    margin-top: 5%;
}
.logoimg{
    margin-bottom: 30px;
    margin-right: 30px;
    float: left;
    margin-left: 10%;
    cursor: pointer;
}
.aboY{
    width: 220px;
    border-bottom: 5px #222 solid;
    margin: 5% 0 3% 40%;
}
.aboY1{
    width: 350px;
    border-bottom: 5px #222 solid;
    margin: 5% 0 3% 37%;
}
.aboH{
    text-align: center;
    line-height: 30px;
    font-weight: 500;
    font-size: 32px;
    color: #131518;
}
.aboH1{
    text-align: center;
    line-height: 30px;
    font-weight: 500;
    font-size: 32px;
    color: #131518;
    margin: 5%;
}
.aboP{
    line-height: 24px;
    font-size: 16px;
    color: #000;
}
.aboP1{
    line-height: 24px;
    font-size: 16px;
    color: #000;
    border-left: 4px solid #000;
    margin-left: 10px;
}
.slick-slide img {
    margin: auto;
}
.aboCar{
    height: 600px!important;
}

@media only screen and (max-width: 600px) {
    .reactPlayer{
        margin-left: 3%;
        width: 344px!important;
        height: 200px!important;
    }
    .logoimg{
        width: 100px;
    }
    .aboY{
        width: 156px;
        margin: 5% 0 3% 26%;
    }
    .aboY1{
        width: 156px;
        margin: 5% 0 3% 26%;
    }
    .aboH{
        font-size: 23px;
    }
    .aboH1{
        font-size: 23px;
    }
  }