/* LOGIN PAGE CSS */
.loginPageGlav {
  background: #222d32;
  font-family: "Roboto", sans-serif;
  height: 100vh;
}
.login-box {
  margin-top: 13%;
  height: auto;
  background: #1a2226;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.login-title {
  margin-top: 15px;
  text-align: center;
  font-size: 30px;
  letter-spacing: 2px;
  margin-top: 15px;
  font-weight: bold;
  color: #ecf0f5;
}
.login-form {
  margin-top: 25px;
  text-align: left;
}
.inputLoginPage[type="text"] {
  background-color: #1a2226;
  border: none;
  border-bottom: 2px solid #0db8de;
  border-top: 0px;
  border-radius: 0px;
  font-weight: bold;
  outline: 0;
  margin-bottom: 20px;
  padding-left: 0px;
  color: #ecf0f5;
  text-align: center;
}
.inputLoginPage[type="password"] {
  background-color: #1a2226;
  border: none;
  border-bottom: 2px solid #0db8de;
  border-top: 0px;
  border-radius: 0px;
  font-weight: bold;
  outline: 0;
  padding-left: 0px;
  margin-bottom: 20px;
  color: #ecf0f5;
  text-align: center;
}
.form-groupLoginPage {
  margin-bottom: 40px;
  outline: 0px;
}
.inputLoginPage:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 2px solid #0db8de;
  outline: 0;
  background-color: #1a2226;
  color: #ecf0f5;
}
.form-control-label {
  font-size: 10px;
  color: #6c6c6c;
  font-weight: bold;
  letter-spacing: 1px;
}
.btnLoginPage {
  width: 40%;
  border-color: #0db8de;
  padding: 7px;
  color: #f3f3f3;
  border: none;
  border-radius: 15px;
  background-color: #55a0c5;
  border-radius: 0px;
  font-weight: bold;
  letter-spacing: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.btnLoginPage:hover {
  background-color: #0db8de;
  right: 0px;
}
.login-btm {
  float: left;
}
.login-button {
  padding-right: 0px;
  text-align: right;
  margin-bottom: 25px;
  margin-left: 9%;
}
.login-text {
  text-align: left;
  padding-left: 0px;
  color: #a2a4a4;
}
.loginbttm {
  padding: 0px;
}
@media (max-width: 916px) {
  .btnLoginPage {
    width: 100%;
  }
}

/* PRODUCT ADMIN CSS */
.imgProductAdmin {
  width: 100%;
  height: 280px;
}
.ProductAdmin {
  margin-top: 5%;
}
.cardAdminProduct {
  margin-top: 20%;
  padding: 10px;
  border: none;
  box-shadow: 0px 2px 7px 1px #6c6c6c;
}
.cardAdminProduct:hover {
  box-shadow: 0px 6px 6px 1px;
}
.btnAdminProduct {
  opacity: 0.9;
  margin: 5px;
}
.btnAdminProduct:hover {
  opacity: 1.1;
  transition: all 0.3s;
}
.daniAdminProduct {
  text-align: center;
}
/* ADD PRODUCT CSS */
.formAdminAdd {
  margin: 10px;
}
.labelADminProd {
  padding: 10px;
  text-align: center;
}
.colAdminAdd {
  width: 75%;
}
.inputImageAdmin {
  margin-top: 4%;
}
.submitAddAdmin {
  margin-left: 33%;
  width: 30%;
  margin-top: 3%;
}

/* USER ADMIN CSS */
.cardUsersAdmin:hover {
  box-shadow: 4px 5px 10px 2px #696262;
}
.cardUsersAdmin {
  box-shadow: 2px 2px 4px 1px #aaa2a2;
}
.userinfGlob {
  text-align: center;
}
.cardUsersAdmin {
  margin-top: 3%;
  padding: 15px;
  border-radius: 17px !important;
}
.userAdmin {
  margin-top: 5%;
}
.iconUsers {
  margin-top: -1%;
  font-size: 75px;
  display: inline-block;
}
.lowName,
.infotmation .lowTel {
  display: inline-block;
}
.imgProdOrder {
  width: 100%;
  height: 200px;
  border-radius: 20px !important;
  padding: 10px;
  margin-top: -5%;
}
.textorder {
  text-align: center;
}
.orderProd {
  border: 2px solid #696262;
  border-radius: 10px;
  margin-left: 3%;
  box-shadow: 2px 4px 4px 2px #b5a7a7;
}
.orderProd:hover {
  box-shadow: 3px 6px 8px 5px rgb(148, 139, 139);
}
.btnOrderAdmin {
  padding: 7px;
  text-align: center;
  background: rgb(214, 20, 20);
  border: none;
  outline: none;
  width: 100%;
  border-radius: 7px;
  color: #fff;
}
.btnOrderAdmin:hover {
  background: rgb(148, 7, 7);
}
.admin-menu{
  margin-top: 1%;
}

/* /* CONACT CSS ADMIN */
.contacAdmin {
  margin-top: 45px;
  background: #eee;
}
@media (min-width: 0) {
  .g-mr-15 {
    margin-right: 1.07143rem !important;
  }
}
@media (min-width: 0) {
  .g-mt-3 {
    margin-top: 0.21429rem !important;
  }
}
.g-height-50 {
  height: 50px;
}
.g-width-50 {
  width: 50px !important;
}
@media (min-width: 0) {
  .g-pa-30 {
    padding: 2.14286rem !important;
  }
}
.g-bg-secondary {
  background-color: #fafafa !important;
}
.u-shadow-v18 {
  box-shadow: 0 5px 10px -6px rgba(0, 0, 0, 0.15);
}
.g-color-gray-dark-v4 {
  color: #777 !important;
}
.g-font-size-12 {
  font-size: 0.85714rem !important;
}
.media-comment {
  margin-top: 20px;
}
.btnContact {
  float: right;
  margin-top: -6%;
  margin-right: 1%;
}

@media (max-width: 568px) {
  .inputsAddPRoduct {
    margin-left: -25%;
    width: 150%;
  }
  .labelADminProd {
    display: none;
  }
  .submitAddAdmin {
    margin-left: 17%;
    width: 50%;
    margin-top: 11%;
  }
  .admin-menu{
    display: block;
  }
}
