.modaliddin{
    width: 100vw;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 99;
    left: 0;
    padding-bottom: 20%;
    height: 100%;
}
.modaliddin .mod{
    width: 65%!important;
    min-height: 60vh;
    margin: 3% auto;
    padding: 30px;
    opacity: 1!important;
    background-color: white;
    position: relative;
}
.modaliddin .kloz{
    position: absolute;
    left: 95%;
}
.modaliddin .mod h1{
    font-size: 2.2em;
    font-weight: 700;
    color: #555555;
    font-family: Poppins, sans-serif;
}
.modaliddin .mod h2{
    font-size: 24px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    color: #B35827;
}
.modaliddin .mod p{
    color: #7A7A7A;
    font-size: 18px;
    font-weight: 400;
}
.modaliddin .mod .sku-wrapper{
    font-size: 12px;
    color: #777;
}
.modaliddin .mod h6{
    color: #222529;
    font-size: 12px;
}
.modaliddin .qty{
    width: 44px;
    height: 3rem;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    line-height: 14px;
    border-radius: 0;
    border-width: 1px 0 1px 0;
    border-color: #e7e7e7;
}
.modaliddin .minus, .plus{
    width: 30px;
    height: 3rem;
    border: solid 1px #e7e7e7;
    color: #222529;
    border-radius: 0;
}
.modaliddin .addToCart{
    margin-left: 5%;
    height: 3rem!important;
    padding: 0 2em;
    margin-bottom: 5px;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: -.015em;
    font-weight: 700;
    line-height: 3rem;
    border: none;
    background-color: #212529;
    color: white;
    transition: all 0.5s ease;
}
.modaliddin .addToCart:hover{
    background-color: #606da6;
    border-color: #606da6;
}


@media (max-width: 480px){
    .modaliddin .mod{
        width: 85vw;
        text-align: center;
        height: 65vh;
    }
    .kloz{
        top: 1%;
        left: 90%!important;
    }
    .modaliddin .mod p{
        text-align: justify;
        color: #7A7A7A;
        font-size: 12px;
    }
    .modaliddin .mod h1{
        font-size: 1.8em;
        font-weight: 700;
        color: #555555;
        font-family: Poppins, sans-serif;
    }
    .modaliddin .mod h2{
        font-size: 18px;
        font-weight: 600;
        font-family: Poppins, sans-serif;
        color: #B35827;
    }
}