.wrapper{
    margin: 0;
    padding: 0;
}
.contact1{
    background-color: white;
    height: 430px;
    padding-top: 1%!important;
}
.contactinput{
    margin: 1% 0 1% 25%!important;
    width: 50%!important;
    border: 2px rgb(224, 221, 226) solid;
    height: 52px!important;
    padding: 7px!important;
    border-radius: 0.25rem!important;
}
.form-control:focus{
    box-shadow: none!important;
    border-color: rgb(224, 221, 226)!important;
}
.contactInput{
    margin: 1% 0 1% 25%!important;
    border: 2px rgb(224, 221, 226) solid;
    width: 50%!important;
    padding: 7px!important;
    border-radius: 0.25rem!important;
}
.bitton{
    width: 13%!important;
    height: 35px!important;
    background-color: #222!important;
    border-radius: 0.25rem!important;
    border: none;
    color: white;
    cursor: pointer;
    margin: 2% 0 0 45%!important;
}
.bitton:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 10px 2px #509abd;
}
.conH{
    text-align: center;
    font-weight: bold !important;
    margin-bottom: 2%!important;
    color: black;
}
.map{
    margin-top: 5%!important;
    background-color: white;
    height: 450px;
    border-radius: 1.5rem;
    padding-top: 2%;
}
.Maps{
    display: flex;
    justify-content: center;
    align-items: center;
}

.maps1{
    width: 700px;
    height: 300px;
    margin: 2% 0;
}

.ymaps-2-1-79-inner-panes {
    overflow: hidden;
    width: 100%;
    border-radius: 0.25rem;
    border: 2px rgb(224, 221, 226) solid;
    height: 100%;
}
.con3p{
    width: 600px;
    text-align: center;
    margin-left: 19%;
    font-size: 15px!important;
    color: black;
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    .wrapper{
        width: 100%;
    }
    .cont{
        padding-top: 20%!important;
      }
    .contact1{
        width: 100%!important;
        margin-left: 0%!important;
    }
    .bitton{
        width: 30%!important;
        margin-left: 35%!important;
    }
    .map{
        width: 100%;
        margin-left: 0%!important;
        height: 88%!important;
    }
    .maps1{
        box-shadow: none;
        width: 90%;
        height: 250px;
        margin: 20px 0;
    }
    .contactinput{
        margin: 4% 0 1% 10%!important;
        width: 75%!important;
      }
      .contactInput{
        margin: 4% 0 1% 10%!important;
        width: 75%!important;
      }
}

.mapH{
    color: white;
    padding-left: 100px;
}