.hdrdpV{
    max-width: 100%!important;
}

.jzxgnk {
    max-width:100%!important
    }

.homiylar {
    padding: 0 100px;
}


.engtepa {
    background-color: #fcf6ef;
    color:#ed7e63 ;
    text-align: center;
    padding: 10px;
    font-size: 15px;
    width: 100%;
}

.homiylar img {
    margin: 0 30px!important;
}

.prev , .next {
    display: none;
}

.img-topp img{
    width: 100%;
}



.left-img img {
    height: 270px;
    width: 210px;
}

.right-img img {
    height: 270px;
    width: 300px;

}

.homiylar {
    background-color: #212529;
    padding: 30px 0;
}

.iRpfsN {
    max-width: 100%!important;
}

.FvmcX{
    justify-content: center;
}

.right-big:hover , .img-topp:hover , .left-img:hover, .right-img:hover {
    opacity: 0.8;
}


.hover-img:hover::before {
    content: '';
    background-color: #808080;
    z-index: 1;
    opacity: 0.1;
    width: 100%;
    left: 0;
    position: absolute;
    height: 100%;
    top: 0;
}


.right-big img {
    width: 100%;
}


.yanam {
    color: #777;
    font-weight: 600;
    cursor: pointer;
    
}

.yanam p {
    display: inline-block;
    border-bottom: 2px solid white;
}

.yanam p:hover{
   
    border-bottom: 2px solid #777;
}

/* tect 4  */

.text-icons {
    font-size: 30px;
}

.text {
    font-size: 15px;
    margin-left: 15px;
    margin-top: 7px;
    line-height: 3px;

}

.left-big-text2 h3 {
    display: inline-block;
    padding: 5px 20px;
    transform: rotate(-5deg);
}

.left-big-text2 span {
    transform: rotate(-100deg);
    display: inline-block;
    margin-top: 10%;
}

.offf2{
    display: inline;
    font-size: 25px;
    font-weight: 500;
    margin-left: 3%;
}

.one-text {
    background-color: white;
    color: black;
}

.one-text2 {
    color: white;
    background-color: #606da6;
}

.rek {
    background-repeat: no-repeat;
    background-position: inherit;
    background-size: cover;
    color: white;
    padding: 5% 7% 3% 7%;
    margin-bottom: 5%;
    width: 100%;
    margin-left: 0px;
}

.teps h1{
    font-size: 45px;

}


.shoppingButton1 {
    position: absolute;
    bottom: 5%;
    right: 10%;
    color: #606DA6;
    padding: 8px 15px;
    font-weight: 700;
    text-decoration: none;
    border: 2px solid #d2dafc;
    transition: all 0.5s;
}

.shoppingButton1:hover {
    color: #212529;
    background-color: #f2f2f2;
    border-color: rgba(0,0,0,0.06);
}

.shoppingButton2 {
    position: absolute;
    bottom: 10%;
    left: 10%;
    color: #606DA6;
    padding: 8px 15px;
    font-weight: 700;
    text-decoration: none;
    border: 2px solid #d2dafc;
    transition: all 0.5s;
}

.shoppingButton2:hover {
    color: #212529;
    background-color: #f2f2f2;
    border-color: rgba(0,0,0,0.06);
}

.shoppingButton3 {
    color: #606DA6;
    padding: 8px 15px;
    font-weight: 700;
    text-decoration: none;
    border: 2px solid #d2dafc;
    transition: all 0.5s;
    margin-top: 10px;
}

.shoppingButton3:hover {
    color: #212529;
    background-color: #f2f2f2;
    border-color: rgba(0,0,0,0.06);
}


.yanassss {
    width: 240px;
    height: 50px;
}


.fsbTzO {
    max-width: 100%!important;
}
.fon {
    height: 270px !important;
    background-repeat: no-repeat;
    background-position: inherit;
    background-size: cover;
    color: white;
    padding: 5% 7% 3% 7%;
    margin-bottom: 5%;
    width: 100%;
    margin-left: 0px;
  }

  

@media only screen and (max-width: 760px) {
    .left-img img {
       margin-bottom: 10px;
        width: 140%
    }
    .right-big img {
        width: 105%;
    }
    .yanam{
        font-size: 150%;
    }
    
    
    .right-img img {
   
        width: 140%;
    
    }

    .shoppingButton1 {
        padding: 2px 10px;
        right: 5%;
    }
    
    .fsbTzO{
        max-width: 350px!important;
    }

    .left-big-text2 span {
        display: none;
    }

    .FvmcX{
        justify-content: start;
    }

    .yanassss {
        width: 50%;
        padding: 10px;
        
    }
   

    .img-bottom {
        display: inline-block!important;
    }
    .fon {
        height: max-content;
      }
}
