.shopping-cart{
	padding-bottom: 50px;
	font-family: 'Montserrat', sans-serif;
	
}



.royxat1{
	border: 1px solid #777777;
}
.shopping-cart.dark{
	background-color: #f6f6f6;
}	

.shopping-cart .block-heading{
    padding-top: 50px;
    margin-bottom: 40px;
    text-align: center;
}

.shopping-cart .block-heading p{
	text-align: center;
	max-width: 420px;
	margin: auto;
	opacity:0.7;
}

.shopping-cart .dark .block-heading p{
	opacity:0.8;
}

.shopping-cart .block-heading h1,
.shopping-cart .block-heading h2,
.shopping-cart .block-heading h3 {
	margin-bottom:1.2rem;
	color: #000000;
}

.shopping-cart .items{
	margin: auto;
}

.shopping-cart .items .product{
	margin-bottom: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 20px;
}

.shopping-cart .items .product .info{
	padding-top: 0px;
	text-align: center;
}

.shopping-cart .items .product .info .product-name{
	font-weight: 600;
}

.shopping-cart .items .product .info .product-name .product-info{
	font-size: 14px;
	margin-top: 15px;
}

.shopping-cart .items .product .info .product-name .product-info .value{
	font-weight: 400;
}

.shopping-cart .items .product .info .quantity .quantity-input{
    margin: auto;
    width: 80px;
}

.shopping-cart .items .product .info .price{
	margin-top: 15px;
    font-weight: bold;
    font-size: 22px;
 }

.shopping-cart .summary{
	border: 1px solid #777777;
    padding: 30px;
}

.shopping-cart .summary h3{
	text-align: center;
	font-size: 1.3em;
	font-weight: 600;
	padding-top: 20px;
	padding-bottom: 20px;
}

.shopping-cart .summary .summary-item:not(:last-of-type){
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shopping-cart .summary .text{
	font-size: 1em;
	font-weight: 600;
}

.shopping-cart .summary .price{
	font-size: 1em;
	float: right;
}

.shopping-cart .summary button{
	margin-top: 20px;
}

.text2 {
	margin-left: 0;
}



.btncount{
	background-color: rgb(48, 48, 48);
	color: white;
}

.btncount:hover{
	background-color: black;
	color: white;
}

.btndelete{
	background-color:rgb(48, 48, 48);
	color: white;
}

.btndelete:hover{
	background-color: red;
	color: white;
}

.btnbuy{
	background-color:rgb(48, 48, 48) !important;
	color: white !important;
}

.btnbuy:hover{
	background-color: black !important;
	color: white !important; 
}
@media (max-width:768px) {
	.shopping-cart .summary{
		margin-top: 0px;
		margin-left: 0px;
		margin-right: 0px;
	}
	.royxat {
		margin-top: 0px;
		margin-left: 0px;
		margin-right: 0px;
	}
}