.nav-items3 {
    text-decoration: none;
    color: #777;
}

.fot-img {
    padding: 5% 0 0 0;
}

.fot-img2 {
    width: 150px;
}

.fot-img h2 {
    padding-left: 30%;
    color: #606da6;
}
.m-2 h4{
    font-size: 120%;
    padding-top: 8%;
}
.tel{
    
    padding-bottom: 1%;
}


.ijtimoiy {
    color: #606da6;
    text-decoration: none;
    margin: 10px 0!important;
    display: block;
}
.fot-img h2 a{
    text-decoration: none;
}
.engPast{
    position: absolute;
    width: 100%;
    height: 6%;
    background-color: #212529;
    margin-top: 13%;
    margin-right: 36%;
}
.d-inline-block a{
    color: #212529 !important;
    font-weight: bolder;
}


@media only screen and (max-width: 760px) {
    .fot-img2 {
        width: 260px;
        margin-left: 15%;
    }
    .m-2{
        font-size: 180%;
    }
    .m-2 h4{
        font-size: 120%;
    }

    .engtepa {
        font-size: 10px;
    }

    .homiylar {
        padding: 0;
        width: 95%;
        margin-left: 10px;
    }

    .right-big {
        margin-top: 20px;
        width: 370px;
        margin-left:0;
        padding: 10px;
    }

    .homiylar img {
        margin: 0 10px !important;
    }
    .fot-img h2 {
        padding-left: 15%;
        color: #606da6;
        font-size: 35px;
        padding-top: 10%;
        padding-bottom: 5%;
        
    }
    .fot-img h2 a{
        text-decoration: none;
    }
    .engPast{
        position: absolute;
        width: 100%;
        height: 6%;
        background-color: #212529;
        margin-top: 190%;
        margin-right: 0;
    }
    
}