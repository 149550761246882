* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

header {
  height: 58px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  height: 58px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.menus {
  display: flex;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 14px;
  
}

.menu-items p b:hover {
border-bottom: 1px solid black
}


.menu-items p b {
border-bottom: 1px solid white;
}


.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}
/* ##################3 */
.nav-items {
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: 0;
    text-decoration: none;
    color: #222529;
    /* padding: 45px 3% 45px 3%; */
}
.nav-select{
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 900;
}
.menu ul {
    margin-top: 45px;
    padding-inline-start: 0;

}

.nav-items:hover {
    color: #222529;
    border-top: 4px solid #606da6;
}

.card-icon,
.admin-icon {
    position: relative;
    margin: 20px;
    font-size: 30px;
    color: #222529;
    cursor: pointer;
}

.count {
    position: absolute;
    top: -1px;
    right: -10px;
    font-weight: 600;
    font-size: 11px;
    background: #ff6363;
    padding: 4px 9px;
    color: white;
    border-radius: 40px;
}



.logo {
    padding-top: 15px;
}
.nav-logo{
  width: 200px;
}


.menu2 a {
    display: block;
    color: white;
    text-decoration: none;
    padding: 5% 10%;
}



.cancel {
    text-align: end;
    font-size: 30px;
}



.menu2 {
    position: absolute;
    top: -45px;
    left: -11px;
    background-color: #fff;
    width: 70%;
    height: 100%;
    z-index: 2;
    transition: width 0.3s linear;
}

.menu2 ul {
    width: 100%;
}


.foo {
    font-size: 40px;
    top: 5%;
    left: 5%;
    z-index: 1;
}
.nav-img{
    width: 150px;
}
.pointer{
cursor: pointer;
}


@media only screen and (max-width: 990px) {
    .items-navbar {
        display: none;
    }
}

@media only screen and (max-width: 760px) {
    .admin {
        position:absolute;
        right: 0;
        top: 0%;
    }.card-icon,
    .admin-icon {
        position: relative;
        margin: 20px;
        font-size: 30px;
        color: #222529;
        cursor: pointer;
        z-index: 9;
    }
    .foo {
        top: 3%;
    }
    .pointer{
      cursor: pointer;
      font-size: 164%;
      }

    .logo {
        position: absolute;
        top: 0;
        left: -20px;
    }
    .nav-logo{
      width: 120px;
    }
}