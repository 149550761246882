.perProduct .mod{
    width: 90vw!important;
    margin: 3% auto;
    padding: 5%;
}
.perProduct .tavsifi{
    margin-left: 2%;
}
.perProduct .mod h1{
    font-size: 2.2em;
    font-weight: 700;
    color: #555555;
    font-family: Poppins, sans-serif;
}
.perProduct .mod h2{
    font-size: 24px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    margin: 2% 0;
    color: #B35827;
}
.perProduct .mod p{
    color: #7A7A7A;
    font-size: 18px;
    font-weight: 400;
}
.perProduct .mod .sku-wrapper{
    font-size: 12px;
    color: #777;
}
.perProduct .mod h6{
    margin-top: 2%;
    color: #222529;
    font-size: 12px;
}
.kategory{
    margin-bottom: 10%;
}
.perProduct .qty{
    width: 44px;
    height: 3rem;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    line-height: 14px;
    border-radius: 0;
    border-width: 1px 0 1px 0;
    border-color: #e7e7e7;
}
.perProduct .minus, .plus{
    width: 30px;
    height: 3rem;
    border: solid 1px #e7e7e7;
    color: #222529;
    border-radius: 0;
}
.perProduct .addToCart{
    margin-left: 5%;
    height: 3rem!important;
    padding: 0 2em;
    margin-bottom: 5px;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: -.015em;
    font-weight: 700;
    line-height: 3rem;
    border: none;
    background-color: #212529;
    color: white;
    transition: all 0.5s ease;
}
.perProduct .addToCart:hover{
    background-color: #606da6;
    border-color: #606da6;
}

.sMod{
    width: 600px;
}

/* rasmni maketi */
.perProduct .minik{
    width: 100px;
    height: 100px;
    border: 2px solid black;
}

.relProd{
    margin-left: 3%;
    margin-bottom: 2%;
    margin-top: 2%;
    font-size: 1.14em;
    color: #222529;
    font-weight: 400;
    font-family: Poppins,  sans-serif;
}

@media (max-width: 480px) {
    .perProduct .mod{
        width: 94vw;
    }
    .perProduct .mod h1{
        font-size: 1.8em;
    }
    .perProduct .mod h2{
        font-size: 18px;
    }
    .perProduct .mod p{
        font-size: 12px;
        text-align: justify;
    }
    .perProduct .minik{
        width: 100px!important;
        height: 100px;
    }
}